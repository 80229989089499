<!-- @file 事件分析 -->
<template>
  <div>
    <div class="l-mr-10 l-d-inline-block">
      <l-select v-model="type" @change="changeLangOrType">
        <l-select-option
          v-for="item in typeList"
          :key="item.value"
          :value="item.value"
        >
          {{ item.name }}
        </l-select-option>
      </l-select>
    </div>
    <l-select v-if="type != 'wx'" v-model="lang" @change="changeLangOrType">
      <l-select-option
        v-for="item in languageList"
        :key="item.value"
        :value="item.value"
      >
        {{ item.name }}
      </l-select-option>
    </l-select>
    <div class="p-event_analysis-total">
      <div class="p-event_analysis-total_item">
        <div>普通用户数</div>
        <div class="p-event_analysis-total_item_num">{{ actionSummary.normalUser }}</div>
      </div>
      <div class="p-event_analysis-total_item">
        <div>拼图用户数</div>
        <div class="p-event_analysis-total_item_num">{{ actionSummary.puzzleUser }}</div>
      </div>
      <div class="p-event_analysis-total_item">
        <div>创作用户数</div>
        <div class="p-event_analysis-total_item_num">{{ actionSummary.creationUser }}</div>
      </div>
      <div class="p-event_analysis-total_item">
        <div>创作与拼图用户数</div>
        <div class="p-event_analysis-total_item_num">{{ actionSummary.puzzleAndCreationUser }}</div>
      </div>
    </div>
    <div>
      <l-button class="l-mr-20" :type="selected == 0 ? 'primary':'default'" @click="quickSelect(0)">今天</l-button>
      <l-button class="l-mr-20" :type="selected == 1 ? 'primary':'default'" @click="quickSelect(1)">昨天</l-button>
      <l-button class="l-mr-20" :type="selected == 2 ? 'primary':'default'" @click="quickSelect(2)">最近7天</l-button>
      <l-button class="l-mr-20" :type="selected == 3 ? 'primary':'default'" @click="quickSelect(3)">最近30天</l-button>
      <span class="l-mr-20">自定义</span>
      <l-range-picker @change="changeDate" />
      <l-button class="l-float-right" @click="exportData">导出数据</l-button>
    </div>

    <div class="p-event_analysis-table">
      <div class="p-event_analysis-table_content">
        <l-table-common
          class="l-mt-20"
          :columns="columns"
          :data-source="tableData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      type: 'h5',
      typeList: [{
        name: 'H5',
        value: 'h5'
      }, {
        name: '微信',
        value: 'wx'
      }],
      lang: 'zh-CN',
      languageList: [{
        name: '简体中文',
        value: 'zh-CN'
      }, {
        name: '繁体中文',
        value: 'zh-TW'
      }, {
        name: 'English',
        value: 'en'
      }],
      selected: 0,
      columns: [
        { title: '日期', dataIndex: 'day' },
        {
          title: '点击【开启拼图】按钮',
          children: [{
            title: '触发用户数',
            dataIndex: 'btnPuzzleUser'
          }, {
            title: '触发次数',
            dataIndex: 'btnPuzzle'
          }],
        },
        {
          title: '查看案例',
          children: [{
            title: '触发用户数',
            dataIndex: 'viewCaseUser'
          }, {
            title: '触发次数',
            dataIndex: 'viewCase'
          }]
        },
        {
          title: '点击【我的创作】按钮',
          children: [{
            title: '触发用户数',
            dataIndex: 'btnMyCreationUser'
          }, {
            title: '触发次数',
            dataIndex: 'btnMyCreation'
          }]
        },
        {
          title: '点击【立即创作】按钮',
          children: [{
            title: '触发用户数',
            dataIndex: 'btnNewCreationUser'
          }, {
            title: '触发次数',
            dataIndex: 'btnNewCreation'
          }]
        },
        {
          title: '拼图页停留超过1分钟',
          children: [{
            title: '触发用户数',
            dataIndex: 'stayPuzzle1User'
          }, {
            title: '触发次数',
            dataIndex: 'stayPuzzle1'
          }]
        },
        {
          title: '填色页停留超过5分钟',
          children: [{
            title: '触发用户数',
            dataIndex: 'stayCreation5User'
          }, {
            title: '触发次数',
            dataIndex: 'stayCreation5'
          }]
        },
        {
          title: '点击【开始填色】按钮且成功进入填色页',
          children: [{
            title: '触发用户数',
            dataIndex: 'btnCreationOkUser'
          }, {
            title: '触发次数',
            dataIndex: 'btnCreationOk'
          }]
        },
        {
          title: '填色完成20个格子以上且点击【完成填色】按钮',
          children: [{
            title: '触发用户数',
            dataIndex: 'btnCreation20OkUser'
          }, {
            title: '触发次数',
            dataIndex: 'btnCreation20Ok'
          }]
        },
        {
          title: '保存预览图片',
          children: [{
            title: '触发用户数',
            dataIndex: 'btnPreviewUser'
          }, {
            title: '触发次数',
            dataIndex: 'btnPreview'
          }]
        },
        {
          title: '保存我的创作',
          children: [{
            title: '触发用户数',
            dataIndex: 'btnSaveCreationUser'
          }, {
            title: '触发次数',
            dataIndex: 'btnSaveCreation'
          }]
        }
      ],
      startDay: '',
      endDay: '',
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 30,
        list: []
      }
    }
  },

  computed: {
    ...mapState({
      actionSummary: state => state.statistics.actionSummary,
      actionSummaryDate: state => state.statistics.actionSummaryDate
    })
  },

  mounted() {
    this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime()
    this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
    this.getSummary()
    this.getList()
  },

  methods: {
    changeLangOrType() {
      this.getSummary()
      this.getList(1)
    },
    quickSelect(type) {
      this.selected = type
      if (type === 0) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime()
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      } else if (type === 1) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime() - 86400000
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime() - 86400000
      } else if (type === 2) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime() - 86400000 * 6
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      } else if (type === 3) {
        this.startDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 00:00:00')).getTime() - 86400000 * 29
        this.endDay = new Date(this.$utils.formatDate(new Date().getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      }
      this.getList(1)
    },
    async getSummary() {
      await this.$store.dispatch('statistics/getActionSummary', {
        language: this.lang,
        type: this.type
      })
    },
    async getList(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('statistics/getActionList', {
          language: this.lang,
          page: page,
          size: this.tableData.size,
          startDay: this.startDay,
          endDay: this.endDay,
          type: this.type
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    changeDate(e) {
      this.startDay = new Date(this.$utils.formatDate(new Date(e[0]).getTime(), 'yyyy/MM/dd 00:00:00')).getTime()
      this.endDay = new Date(this.$utils.formatDate(new Date(e[1]).getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      this.getList(1)
    },
    exportData() {
      const url = `/api/admin/instance/statistic/action-list-export?language=${this.lang}&startDay=${this.startDay}&endDay=${this.endDay}`
      this.$store.dispatch('download', { url })
    }
  }
}
</script>

<style lang="scss">
.p-event_analysis-total {
  display: flex;
  margin: 20px 0;
  .p-event_analysis-total_item {
    margin-right: 20px;
    border: 1px solid #1890ff;
    border-radius: 8px;
    padding: 20px;
    width: 200px;
    font-size: 16px;
    box-shadow: 0 0 5px #a6d4ff;
    .p-event_analysis-total_item_num {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.p-event_analysis-table {
  width: 100%;
  overflow-x: auto;
  .p-event_analysis-table_content {
    width: 2800px;
  }
}
</style>
